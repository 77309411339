<template>
  <div class="wrapper">
    <!-- <img src="/img/INFORMATIVO_epublic.png" width="100%" /> -->
    <img src="img/congelamento.jpeg" style="width: 100%" />
    <!-- <v-card elevation="2" class="login-card">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo.png" width="100" />
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form ref="form" @submit.prevent="handleSubmit(logar)">
            <ValidationProvider name="Login de acesso" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.email"
                filled
                prepend-inner-icon="mdi-account-outline"
                label="Login de acesso"
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <ValidationProvider name="Senha" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.password"
                filled
                prepend-inner-icon="mdi-lock-outline"
                label="Senha"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages="errors"
                required
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
            <v-btn
              block
              color="primary"
              type="submit"
              :class="loginButtonClass"
              :disabled="logando"
              :loading="logando"
            >
              Entrar no sistema
            </v-btn>
          </v-form>
        </ValidationObserver>
        <a
          @click="$router.push({ name: 'resetPassword' })"
          target="__blank"
          class="help-section d-flex flex-column align-center"
          v-bind:class="{ 'animate__animated animate__headShake': animarAjuda }"
        >
          <span class="mt-4">Esqueci minha senha!</span>
        </a>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      credentials: { email: "", password: "" },
      showPassword: false,
      logando: false,
      loginButtonClass: "",
      animarAjuda: false,
    };
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    // this.$Tawk.$hideWidget();
    const switchAnimarAjuda = () => {
      this.animarAjuda = !this.animarAjuda;
      setTimeout(() => switchAnimarAjuda(), 2000);
    };
    switchAnimarAjuda();
  },
  methods: {
    async logar() {
      this.logando = true;
      try {
        await this.$services.authService.logar(this.credentials);
        this.$toast.success("Logado no sistema com sucesso!");
        const user = await this.$services.authService.getCurrentUser();
        const { name, redirect } = this.$route.query;
        let has_permission = false;
        user.permissions.forEach((e) => {
          if (name === e || `${name}.*` === e || `${name?.split(".")[0]}.atualizar` === e) {
            has_permission = true;
          }
        });
        if (redirect && has_permission) this.$router.push(redirect);
        else this.$router.push({ name: "home" });
      } catch (error) {
        this.$toast.danger(error.error.message);
        this.loginButtonClass = "animate__animated animate__headShake";
        setTimeout(() => (this.loginButtonClass = ""), 500);
      }
      this.logando = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../../assets/background_tratato_para_web.jpg");
  background-size: cover;
}

.wrapper > .login-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}

.help-section > i {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
}

.help-section {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
</style>
